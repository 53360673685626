import 'slick-carousel/slick/slick.min';

export default class Slider {

  constructor(el) {
    this.el = $(el);
    this.slider = this.el.find('.js-slider');
    this.sliderCreated = false;
    this.initSlider();

    $(window).on('resize', this.initSlider.bind(this));

}

  initSlider() {


      if (this.sliderCreated === false) {

        this.slider.slick({
          arrows: true,
          dots: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
              }
            }
          ]
        });

        this.sliderCreated = true;
      }


  }


}
