
export default class ContentFadein {

    constructor() {

        var options = {
            rootMargin: '100px',
            threshold: [0, 0.25, 0.5, 0.75, 1.0]
        }

        this.observer = new IntersectionObserver(this.contentInView, options);
        let $images = $('.content-fade-in');
        let i = 0;
        let il = $images.length;

        for (; i < il; i += 1) {
            this.observer.observe($images[i]);
        }
    }

    contentInView(evt) {

        var i = 0,
        il = evt.length,
        entry;

        for (; i < il; i += 1) {
            var target = evt[i].target;
            var intersectionRatio = evt[i].intersectionRatio;

            if (intersectionRatio === 0) {
                $(target).removeClass('content-in-view');

            } else if (intersectionRatio > 0.5) {
                //Show
                $(target).addClass('content-in-view');
                //this.observer.unobserve(evt[0].target);
            }
            if (evt[i].boundingClientRect.top < 0) {
                target.setAttribute('data-seen', true);
            } else {
                target.removeAttribute('data-seen');
            }
        }
    }
}
