import 'jquery';
// import './vendor/picturefill';
import Nav from './components/nav';
import Slider from './components/slider';
import PortfolioSlider from './components/portfolio-slider';
import ContentFadein from './components/content-fadein';
import StartAnimation from './components/start-animation';

jQuery(document).ready(() => {

  new Nav();

  $('.slides-section').each(function() {
    new Slider(this);
  });

  $('.portfolio').each(function() {
    new PortfolioSlider(this);
  });

  new ContentFadein();
  new StartAnimation();

  // // Correct the height of all svgs on ie11
  // $('.ie-svg-fix').each(function() {
  //   svgIeFixer(this);
  // });



});
