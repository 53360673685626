import 'slick-carousel/slick/slick.min';

export default class PortfolioSlider {

  constructor(el) {
    this.el = $(el);
    this.slider = this.el.find('.js-slider');
    this.sliderCreated = false;
    this.initSlider();

    $(window).on('resize', this.initSlider.bind(this));

}

  initSlider() {

    if ($(window).innerWidth() < 768) {
      if (this.sliderCreated === false) {

        this.slider.slick({
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              }
            }
          ]
        });

        this.sliderCreated = true;
      }
    } else {
      if (this.sliderCreated === true) {
        // Large device. We don't need a slider
        this.sliderCreated = false;

        setTimeout(() => {
          this.slider.slick('unslick');
        }, 400);


      }
    }

  }


}
