export default class Nav {

  constructor() {
    this.html = $('html');
    // Desktop
    this.nav = $('.js-mainnav');
    this.navToggleBtn = $('.js-togglenav');

    // Listen for escape key press
    $(document).on('keyup', (e) => {
      if (e.keyCode == 27) { // escape key maps to keycode `27`
        if ($('html').hasClass('nav-toggled')) {
          this.toggleNav(e);
        }
      }
    });

    $(document).on('nav-close', () => {
      this.toggleNav();
    });

    this.doBindings();

  }

  doBindings() {
    this.navToggleBtn.on('click', this.toggleNav.bind(this));
  }


  toggleNav() {

    if (!this.navToggleBtn.hasClass('is-active')) {
      this.navToggleBtn.addClass('is-active').attr('aria-expanded', 'true');
      this.html.addClass('nav-toggled');
      this.nav.attr('aria-expanded', 'true');
    } else {
      this.navToggleBtn.removeClass('is-active').attr('aria-expanded', 'false');
      this.html.removeClass('nav-toggled');
      this.nav.attr('aria-expanded', 'false');
    }

  }

}
